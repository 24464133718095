import React, { Component, useRef, useEffect, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'

import {
		Menu,
		Container,
		Icon,
		Ref,
		Loader,
		Dropdown,
		Button,
	} from 'semantic-ui-react'

import user_model from 'models/user_model';
import org_model from 'models/org_model';
import AdminMenu from '../MenuNav/AdminMenu'
import MainMenuItems from '../MenuNav/MainMenuItems'
import SideAwareDropdown from 'xAppLib/MenuNav/SideAwareDropdown'
import { useUser } from 'xAppLib/Hooks';
import UserAvatar from 'xAppLib/Users/UserAvatar';
import AppendIf from './AppendIf';
import ResultsBanner from 'views/UIelems/ResultsBanner'
import { ApprovalBadge } from 'views/instcons/InstCons_Approve_WR';
import LoginPopup from '../Users/LoginPopup';
import UrgentFollowupBanner from "views/UIelems/UrgentFollowupBanner";
import MegaMenu from '../MenuNav/MegaMenu';
import { cls } from 'views/NUI/utils'
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import NeedReviewBanner from "../../views/UIelems/NeedReviewBanner";
import NeedeScriptBanner from "../../views/UIelems/NeedeScriptBanner";
import Badge from "views/NUI/Badge";
import gtm from 'xAppLib/providers/gtm';
import IcosmMenu from '../MenuNav/IcosmMenu';

const admin_menu = (lvl) => {
	return <Menu.Menu>
				<SideAwareDropdown
					cls="admin-menu"
					items={(isRight, isEnter)=><AdminMenu isRight={isRight} isEnter={isEnter} level={lvl} />}
					trigger={<React.Fragment><Icon name="chess king" />Admin</React.Fragment>} 
				/>
			</Menu.Menu>
}

const Header = ({ onToggleMenu }) => {
	const loc = useLocation()
	const user = useUser()
	const ref = useRef()
	const wrapRef = useRef()
	const menuRef = useRef()
	const curr_page = loc.pathname
	const admin = user.claims.admin

	const scrollTarget = app.settings.is_ionic ? document.getElementById('app') : window
	const navig = (app.site_status?.show_navigator || app.user?.claims?.admin || app.user?.claims?.supp || app.user?.claims?.mark) && !curr_page.startsWith('/m/pharm') && app.settings.iscr

	const extra = app.acl.is_work

	const checkMenu = useCallback(function () {
		if (!ref.current)
			return 
		let scheduled 
		if (!scheduled) {
			window.requestAnimationFrame(()=> {
				const scroll = scrollTarget.scrollY || scrollTarget.scrollTop || 0
				if (scroll > 0) {
					if (!ref.current?.classList.contains('scroll'))
						ref.current?.classList.add('scroll')
				}
				else {
					if (ref.current?.classList.contains('scroll'))
						ref.current?.classList.remove('scroll')
				}
				
				// if (menuRef.current && wrapRef.current) {
				// 	if (menuRef.current.scrollWidth > wrapRef.current.offsetWidth) {
				// 		if (!ref.current?.classList.contains('switch'))
				// 			ref.current.classList.add('switch')
				// 	} else {
				// 		if (ref.current?.classList.contains('switch'))
				// 			ref.current.classList.remove('switch')
				// 	}
				// }
				
				scheduled = false;
			});
			scheduled = true;
		}

	},[])

	useEffect(_=>{
		
		// TODO: do we even need scroll event? 
		scrollTarget.addEventListener('scroll', checkMenu)
		scrollTarget.addEventListener('resize', checkMenu)
		
		return () => {
			scrollTarget.removeEventListener('scroll', checkMenu)
			scrollTarget.removeEventListener('resize', checkMenu)
		}
	},[])

	useEffect(_=>{
		checkMenu()
	},[user])

	if (app.settings.is_bare)
		return null

	const menu_alt = user.user_in || ['/privacy', '/customer-terms-and-conditions', '/platform-terms-and-conditions'].includes(location.pathname.replace(/\/$/, ''))

	return (
		<header id="main-menu" ref={ref} className={cls(extra && 'extra', menu_alt ? 'user-in' : 'no-user')}>
			
			{(app.settings.icosm && !user.user_in) && <Link className="-my-4 text-[13px] tracking-[.17em] mb-4 block h-[49px] uppercase !bg-white text-black hover:underline !w-full text-center py-4" href="#" onClick={()=>window.Intercom('show')}><div className="w-full">Speak to cosmetic support now</div></Link>}
			  
			<Container fluid={app.settings.is_cmg}>

				{/* Top Menu Row */}
				<Menu className={cls('top-menu', navig && 'navigator')}>

					{/* Side Menu IC */}
					{ (app.settings.icosm && !app.runtime.hide_nav) &&
						<Menu.Item header onClick={_=> onToggleMenu(true)} className={cls('burger', "md:!hidden")}>
							<SIcon name="burger-menu" cls="h-[40px] w-[40px]" bold/>
						</Menu.Item>
					}
					
					{/* Logo */}
					<Menu.Item header as={Link} to='/'>
						<img className='logo' alt={app.runtime.name} src={app.settings.icosm && menu_alt && app.runtime.sidemenuLogo || app.runtime.headerLogo || '/images/logos/instant-scripts-logo2-wide.svg'} />
						{__LOCAL_PROD__ && <Badge small color='red' className="ml-2 scale-75 origin-top-left">PROD</Badge> }
					</Menu.Item>

					{/* Main Menu */}
					{!app.runtime.hide_nav && 
						<div className={cls(`main-menu-cont hidden ${app.settings.icosm ? 'md:flex' : 'lg:flex'} flex-auto justify-end flex-nowrap`)}>

							{app.settings.iscr && !app.settings.is_mob_sz && app.acl.app_use && <MegaMenu />}

							{app.settings.icosm && !app.settings.is_mob_sz && !user.user_in ? <IcosmMenu />
							:
								<Menu.Menu className={cls('flex items-center', 
									app.settings.iscr && extra && 'md:hidden' ||
									app.settings.is_cmg && !extra && 'hidden cmg-md:flex' ||
									app.settings.icosm && (extra && !admin && 'md:flex') || 
									!app.settings.iscr && (extra && user.user_in && 'hidden')
								)}>
									<MainMenuItems />
									{user.claims.admin && admin_menu(0)}
								</Menu.Menu>
							}

						</div>
					}
					
					{/* Login */}
					<Menu.Menu position="right" className={cls('user-menu items-center ml-2 gap-2 md:gap-4 flex-row')}>
						{navig && 
							<Button
								className="ui green button rounded-md"
								id="get-started"
								content="Get Started"
								as={Link}
								to='/navig'
								onClick={()=>gtm.log('wizard', 'navig', {navig_btn: 'open'})}
								key={'header_menu_navig'} /> 
						}

						{ user.user_in==null
								&& <Loader active inline size="tiny" style={{alignSelf:'center'}} /> 
						}
						{app.settings.icosm && !app.acl.is_work &&
							<Menu.Item
							content={'Join'}
							className='join'
							active={curr_page === '/join'}
							as={Link}
							to={'/join'}
							key={'header_menu_mob_login'} />
						}
						{ (user.user_in==false || app.runtime?.login_type=='popup'&&app.state.in_sgup_st2) // && !app.settings.iprep && !app.settings.ileg && !app.settings.icann
								&& !(app.dvc.org.type=='pharm'
									&& (document.location.hostname=="pharmapp.instantscripts.com.au"||document.location.hostname=="safe-scripts.firebaseapp.com")
									&& (app.settings.is_tablet || app.settings.is_mob)
									)
								&& (app.runtime?.login_type=='popup' ?
								    <LoginPopup/>
									: 
									<Menu.Item
									content={'Log in'}
									active={curr_page === '/login'}
									as={Link}
									to={'/login'}
									key={'header_menu_mob_login'} />)
						}

						<ApprovalBadge/>

						{ user.user_in &&
							<Dropdown item id="profile" trigger={app.settings.icosm ? <><span className="hidden md:block">PROFILE</span><span className="block md:hidden"><img src="https://storage.googleapis.com/icosm/2/ic-login-mobile-button.svg"/></span></> : <UserAvatar />} icon={false} data-testid='user-avatar'>
								<Dropdown.Menu direction='left'>
									<Dropdown.Header>
										<AppendIf
											cont={user.name || user.email}
										/>
			
										<AppendIf
											cont={user.org?.name}
											beforeContent={<br />}
										/>
			
										<AppendIf
											cont={user.role}
											beforeContent={<React.Fragment><br />(</React.Fragment>}
											afterContent=")"
										/>
									</Dropdown.Header>
									<Dropdown.Divider />
			
									<Dropdown.Item icon='user' as={Link} to='/profile' content="My profile" />
									{app.acl.is_mang && app.acl.is_cosm && <Dropdown.Item icon='building' as={Link} to='/org/my-clinic-profile' content="Clinic Profile" />}
									{app.acl.is_nurse && <Dropdown.Item icon='certificate' as={Link} to='/cosm/audit/list/practitioner' content="Audit history" />}
									{user.usePassword && <Dropdown.Item icon='lock' as={Link} to='/profile-password' content="Change password" />}
									{(app.acl.is_admin || app.acl.is_nurse || app.acl.is_doc) && (
										<Dropdown.Item icon='video' as={Link} to='/vidcall-troubleshooting' content="Test VidCall" />
									)}
									<Dropdown.Item icon='user close' onClick={ ()=>user_model.logout() } content="Logout" />
			
								</Dropdown.Menu>
							</Dropdown>
						}
					</Menu.Menu>
					
					{/* Side Menu */}
					{( (!app.settings.iscr && !app.runtime.hide_nav) || app.settings.iscr )&& ((user.user_in && app.settings.icosm) || !app.settings.icosm) &&
						<Menu.Item header data-testid="burger-menu" onClick={_=> onToggleMenu(true)} className={cls('burger', app.settings.iscr && 'menu-lg:!hidden', (app.settings.icosm && user.user_in) && "!hidden md:!flex xl:!hidden")}>
							<SIcon name="burger-menu" cls="h-[40px] w-[40px]" bold/>
						</Menu.Item>
					}
					
				</Menu>
				
				{/* Bottom Row */}
				<Menu style={{minHeight: 'auto', marginTop:0, padding:0 }} className='justify-center'>	
					
					<div className={cls('flex-auto justify-between', user.user_in && 'user-in',
						app.settings.icosm && (!extra && user.user_in && 'hidden' || extra && admin && 'flex' || extra && !admin && 'hidden' || !user.user_in && 'hidden md:flex lg:hidden') ||
						app.settings.is_cmg && !extra && 'flex cmg-md:hidden' ||
						app.settings.iscr && (extra ? 'flex' : 'hidden') ||
						!app.settings.iscr && (!extra || !user.user_in) && 'flex lg:hidden'

					)}>
						<Menu.Menu className={cls('w-full flex flex-wrap flex-auto justify-between',
							app.acl.is_pharm && 'md:justify-end')}>
							<MainMenuItems />
							{user.claims.admin && admin_menu(1)}
						</Menu.Menu>
					</div>

				</Menu>
				{/* Banners  */}
				<div className=" flex-col flex md:flex-row gap-2 xs:max-w-3xl max-w-none mx-auto flex-wrap">
					{!app.settings.icosm && <ResultsBanner  hide_zero={true} />}
					{app.acl.can_urgent_care && <UrgentFollowupBanner/>}
					{app.acl.can_manual_review && !app.settings.icosm && <NeedReviewBanner/>}
					{app.acl.can_escript_review && !app.settings.icosm && <NeedeScriptBanner/>}
				</div>
				
			</Container>
		</header>
	);
}
 
export default Header;
