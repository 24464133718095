import {useEffect,useState,useCallback} from 'react'
import { waiting_room_model } from "../../models/waiting_room_model";
import doc_model from "../../models/doc_model";
import {prioritorise_app_scripts} from "./util";
import { useSiteStatus, useUser, useUserData } from 'xAppLib/Hooks';
import { useCanApprove } from './Hooks';
import scripts_list_model from 'models/scripts_list_model';
import { useLazyLoad } from 'views/scripts/hooks';
import { useRef } from 'react';
import { from_melb_ui_tm } from 'helpers/datetime';
import moment from 'moment';
import { batched } from "../../xAppLib/libs/batched";

const DEBUG = false

function load_one(sid) {
	return scripts_list_model.load_by_sid(sid, {show_list: 'approve'});
}
const load_from_events = async sids => {
	const unique = [...new Set(sids)];
	const res = await scripts_list_model.load_scripts_list({ filters: { sids: unique }, show_list: 'approve'});
	if (res.res === 'ok') {
		const byScriptId = Object.fromEntries((Array.isArray(res.listScripts) && res.listScripts || []).map(scr => [scr.sid, scr]));

		return sids.map(sid => byScriptId[sid]);
	}

	return Promise.reject(res);
}
const batched_load = batched(load_from_events, 1000);

const def = {}
const DELAY_SHOWING = _=> app.settings.dev_env || app.user?.claims?.admin && !app.user?.claims?.doc ? 0 : 2 // minutes

const WatchApprovals = ({children}) => {
	const [nextToAppear, setNextToAppear] = useState(null);

    const user = useUser()

	const [cache, actions] = useLazyLoad(batched_load);

	const [records, setRecords] = useUserData('approval',null)

	const can_approve = useCanApprove()

	const recordsRef = useRef({rt:[],pg:{}});

	const approve_wr = useSiteStatus('approve_wr') || def

	const gone = useRef(false)

	const lag_ms = useRef(0);

	const doc_delay_s = useRef(0);
	useEffect(() => {
		const reset = () => {
			doc_delay_s.current = 0;
		};

		if (app.user.claims.doc_id) {
			const unsub = doc_model.watch_record(`${app.user.claims.doc_id}/_app_scr_delay`, s => doc_delay_s.current = s, {ce: reset});

			return () => {
				unsub();
				reset();
			};
		}

		return reset;
	}, [app.user.claims.doc_id]);

	const merge_records = useCallback(()=>{
		if (gone.current)
			return

		const {rt,pg} = recordsRef.current

		const lag_check = rt.find(x => x.rev_tm && x.srv_tm && x.owner?.uid === app.user.uid);
		const updated_lag = lag_check ? lag_check.rev_tm - lag_check.srv_tm : null;

		if (updated_lag && Math.abs(updated_lag - lag_ms.current) > 100) {
			lag_ms.current = updated_lag;
		}
		DEBUG && console.log(`Clock difference: ${lag_ms.current}ms`);
		const lag = lag_ms.current / 1000;

		const records = rt.map(rec=>{
			const {loading, value, error } = pg[rec.sid]||{loading:true}
			return {
				...rec,
				loading,
				...value,
				error,
				showAt: moment(rec.created).add(DELAY_SHOWING(), 'minutes').add(lag, 'seconds').add(doc_delay_s.current, 'seconds')
			}
		});
		const now = moment();
		const {recs, future} = records.reduce((acc, n) => {
			if (n.showAt.isSameOrBefore(now) || n.owner?.uid == app.user.uid) {
				acc.recs.push(n);
			} else {
				acc.future.push(n);
			}
			return acc;
		}, { recs: [], future: [] });

		DEBUG && console.log("records diff times",records.map(({created,snum})=>({snum,diff:now.diff(created,'minutes')})));
		DEBUG && console.log("now is",now.toString(),"compared to", moment().toString(),"and lag is",lag);
		DEBUG && console.log("setting records",recs.length,next.length,{records:records.map(r=>r.snum)});

		setRecords(recs)

		const next = Math.min(...(future.map(x => x.showAt.toDate().getTime())));
		DEBUG && console.log("next at",next);
		setNextToAppear(isFinite(next) ? next : null);
	},[setRecords])


	useEffect(() => {
		if (nextToAppear > Date.now()) {
			const ms = nextToAppear - Date.now();
			const id = setTimeout(merge_records, ms + 100);
			return () => {
				clearTimeout(id);
			};
		}
	}, [nextToAppear]);

	useEffect(()=>{
		return () => {
			gone.current = true
		}
	},[])

	useEffect(()=>{
		DEBUG && console.log("onCache",cache)
		recordsRef.current.pg = cache
		merge_records()
	},[cache])

	useEffect(()=>{
		let prev = [];

        if (!can_approve) 
			return null

		return waiting_room_model.watch_approvals((records) => {
			DEBUG && console.log("onData",records);
			const sorted = Array.isArray(records)
				? records
				: prioritorise_app_scripts(records);
			const exclude = approve_wr.exclude || []
			const final = sorted
				.filter(r => {
					if (exclude.includes(r.script_type) || r.rejected?.includes(user.claims.doc_id)) return false;

					if (app.acl.is_admin || app.acl.is_supp) return true;

					return true;
				})
				.map(r => ({...r, created: from_melb_ui_tm(r.add_tm)}))
		
			recordsRef.current.rt = final
			merge_records()
			const next = Object.fromEntries(final.map(x => [x.sid, x]));
			const changed = Object.keys(next).filter(sid => !prev[sid] || next[sid].upd !== prev[sid].upd);
			changed.forEach(sid => {
				actions.load(sid, true).then(() => false /* ignore */);
			});

			Object.keys(prev).filter(sid => !next[sid]).forEach(sid => {
				actions.remove(sid);
			});
			prev = next;
		});
    
	},[user,approve_wr,can_approve,merge_records])

    return children?.(records) || null;

}
 
export default WatchApprovals;