import db_lib from "../xAppLib/libs/db_lib";
import instcons_global_model from "./instcons_global_model";
import { sort_med_scripts_for_wr, withUr } from "./utils";

const DEBUG = false;

export class waiting_room_model extends db_lib {
	static get FRDB_LOC() {
		return 'WaitingRoom';
	}

	static watch_approvals(onData, onError) {
		const subscriptions = {};
		const full_records = {};

		// We need to pull the MedScript record from firebase because that is used to control who owns the record all
		// from the frontend (postgres is unaware of a doctor "locking" an appscript record). We _could_ start pushing
		// this to WaitingRoom, however I want to keep that small and read-only (as MedScripts was _meant_ to be).
		// So add a bunch of complexity to avoid making a teeny-tiny record mutable... makes total sense. But also,
		// patients can read from MedScripts to get live updates on their request and that's the source of truth for
		// what's happening with the record, we don't want to also have them read from a transient WaitingRoom record.
		const stop_watching = this.watch_all_records(
			(_records) => {
				const records = _records ?? {};
				const added = Object.keys(records).filter(sid => !(sid in subscriptions));
				const removed = Object.keys(subscriptions).filter(sid => !(sid in records));

				for (const sid of added) {
					// New record in the waiting room? Subscribe.
					// Since we're watching a specific record (well, set of records) this shouldn't be putting too
					// much load on the client or rtdb. Besides, we're making a bunch of API calls to BE _as well_

					DEBUG && console.log('waiting_room_model::watch_approval / adding subscription', sid);
					subscriptions[sid] = instcons_global_model.watch_record(sid, full => {
						full_records[sid] = {...records[sid], ...full};
						DEBUG && console.log('waiting_room_model::watch_approval / full records', full_records);
						onData(full_records);
					});
				}

				for (const sid of removed) {
					// Dropped out of the waiting room? Unsubscribe.
					DEBUG && console.log('waiting_room_model::watch_approval / remove subscription', sid);
					subscriptions[sid]();

					delete subscriptions[sid];
					delete full_records[sid];
				}

				onData(full_records);
			},
			{where_key: 'wr', where_val: 'app_scripts', ce: onError},
		);

		return () => {
			stop_watching();

			// unsubscribe from everything.
			for (const sid in subscriptions) {
				subscriptions[sid]();
			}
		};
	}

	static watch_urgent_fu(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'urgent_fu', ce: onError});
	}

	static watch_need_review(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'need_review', ce: onError});
	}

	static watch_escript_review(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'wr', where_val: 'escript_review', ce: onError });
	}

	static awaiting_gp_consult(onData, onError) {
		return this.watch_all_records(
			records => onData(sort_med_scripts_for_wr(records).map(withUr)),
			{where_key: 'wr', where_val: 'await_consult', ce: onError},
		);
	}

	static awaiting_urgent_fu(onData, onError) {
		return this.watch_all_records(
			records => onData(sort_med_scripts_for_wr(records).map(withUr)),
			{where_key: 'wr', where_val: 'urgent_fu', ce: onError},
		);
	}

}
