import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet"

import { Container, Header, Button, Icon } from 'semantic-ui-react'
 import { Link, useNavigate } from 'react-router-dom'

import moment from 'moment-timezone'

import UniForm from "xAppLib/UniForm/UniForm"
import UniFormSUI from "xAppLib/UniForm/UniForm-SUI"
import Photos from 'xAppLib/Photos/Photos';

import scripts_list_model from 'models/scripts_list_model'
import script_model from 'models/script_model'

import API_service from 'xAppLib/providers/API_service'
import BackButton from 'xAppLib/UIelems/BackButton';
import obj_diff from 'xAppLib/helpers/obj_diff';
import instcons_global_model from "../../models/instcons_global_model";
import TestMyVideo from "../supp/TestMyVideo";
import { useDocConsPtReady } from "./hooks";

import { FRIENDLY_DATETIME_DAY_MONTH } from '../booking/formatting';
import { getClientBookingTimezone } from '../booking/shared';

import SearchPharm from "views/UIelems/SearchPharm";


function LinkToVideoConsult({sid}) {
	const [frb_rec, setFrbRec] = useState(null);
	useEffect(() => instcons_global_model.watch_record(sid, setFrbRec), [sid]);

	useDocConsPtReady(sid);

	if (!(['mhcp', 'mhcprev'].includes(frb_rec?.req_type) || frb_rec?.sessionId)) return null;

	return (<>
		<p>You can test your camera is working properly before your video consult starts:</p>
		<TestMyVideo embedded />
		<p className="mt-4">You will be notified when the doctor would like to start a video consult:</p>
		<Button color="green" onClick={_ => app.history.push(`/doccons_view/${sid}/_`)} disabled={!frb_rec?.sessionId}>
			<Icon name='camera'/> Join Video Consult with Doctor
		</Button>
	</>);
}

const DocCons_req = props => {
	const { embedded = false } = props

	const [loading, setLoading] = useState(false);
	const [scr_dets, setScr] = useState();
	const [status, setStatus] = useState();
	const [mob, setMob] = useState();

	const {sid, mobilehash} = props?.match?.params || props

	const navigate = useNavigate();

	//	------------------------	------------------------	------------------------

	useEffect( async _ => {
		load_cons_dets()
	}, [sid]);

	const load_cons_dets = async _ => {
		setLoading(true)

		const scr_dets = await API_service.load_data(
				'doccons_req',
				{
					act: 'load',	
					sid: sid,
					mobilehash: mobilehash,
				}
			)

		if (scr_dets.res=='ok') {
			setScr(scr_dets)
			setStatus(scr_dets.st)
			setMob(scr_dets.cons_phone)
		} else {
			setStatus('scr_err')
		}

		setLoading(false)

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const respondCallSameNumber = async _ => {
		if (loading){
			console.log('Already loading, please wait')
			return;
		}

		setLoading(true)

		const res = await API_service.load_data(
				'doccons_req',
				{	
					act:'recall_same_number',
					sid: sid,
					mobilehash: mobilehash,
				}
			)

		load_cons_dets()
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const respondCallAgain = async ({ call_hour_restriction }) => {
		if (loading){
			console.log('Already loading, please wait');
			return;
		}

		setLoading(true);

		await API_service.load_data(
            'doccons_req',
            {
                act:'recall_same_number',
                sid: sid,
                call_hour_restriction,
            }
        )

		load_cons_dets();
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	const save_phts = async phts => {

		if (loading){
			console.log('Already loading, please wait')
			return;
		}
		setLoading(true)

		const data_diff = obj_diff(scr_dets.cons_photos, phts)

		console.log('EditScript :: save_phts', data_diff, scr_dets.cons_photos, phts)

		if (!data_diff.to || !Object.keys(data_diff).length) 			return

		let params = {sid, phts, diff:{...data_diff, part:'phts'}}

		const upd_res = await API_service.load_data(
									'scripts/upd_script', 
									params,
									r => console.log('scr updated', r)
								) 
					
	    load_cons_dets()

	}

	//	------------------------	------------------------	------------------------

	const respondCallDifferentNumber = async number => {
		if (loading){
			console.log('Already loading, please wait')
			return;
		}

		setLoading(true)

		const res = await API_service.load_data(
				'doccons_req',
				{	
					act:'change_cons_dets',
					sid: sid,
					mobilehash: mobilehash,
					new_number: number
				}
			)

		load_cons_dets()
	}

	//	------------------------	------------------------	------------------------

	const respondCancelCall = async _ => {
		if (loading){
			console.log('Already loading, please wait')
			return;
		}

		setLoading(true)

		const res = await API_service.load_data(
				'doccons_req',
				{	
					act:'cancel_consult',
					sid: sid,
					mobilehash: mobilehash,
				}
			)

		app.user.reload_profile()

		if (embedded) {
			load_cons_dets()
		} else {
			navigate("/history")
		}
	}

	const respondIssuePaper = async pharm => {
		if (loading){
			console.log('Already loading, please wait')
			return;
		}
		if (!pharm.sendto_oid) {
			return
		}
		setLoading(true)

		const res = await API_service.load_data(
				'doccons_req',
				{	
					act:'issue_paper',
					sid: sid,
					mobilehash: mobilehash,
					sendto_oid:pharm.sendto_oid
				}
			)

		if (res?.res=='ok') {
			app.functions.add_message({type: 'positive', header:'Completed', content:'Paper script has been issued.'})
			setStatus('done')
		} else {
			app.functions.add_message({type: 'negative', header:'Error', content:'Unable to issue a Paper script. Please contact our support team.'})
		}
		
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const render_loading_page = _ => {
		return <h3>Loading...</h3>
	}

	const render_invalid = _ => {
		return <>
					<h1>Invalid link</h1>
					<p>This link is not valid</p>
					<p>If you think this is an error, please check the link and try again.</p>
					<p>Please contact support if this error is unresolved at <a href="mailto:info@instantscripts.com.au">info@instantscripts.com.au</a></p>
					<p><a href="/">Return to Home</a></p>
				</>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const render_view_cons_dets = _ => {

		return <>
					<h2 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
						Consultation Request Details
					</h2>

					<h3>Phone number: {mob}</h3>
					<h3>Description: {scr_dets.cons_desc}</h3>
					<h3>Allergy: {scr_dets.cons_allergy}</h3>

			</>
	}

	//	------------------------	------------------------	------------------------

	const render_edit_cons_dets = _ => {

		return <>
					<h4 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
						Consultation Phone Number
					</h4>

					<UniForm
						onActForm={ d => respondCallDifferentNumber(d.cons_phone) } 

						render_children={true}
						debug={true}
						fields={script_model.COMM_DCNS_FIELDS.filter( f => ['cons_phone'].includes(f?.name) )}
						// fields={script_model.COMM_DCNS_FIELDS.filter( f => !['cons_ref_spec', 'cons_ref_name', 'cons_ref_email'].includes(f?.name) )}

						// loading={loading}

						defaultValues={{
										cons_phone: mob,
										cons_desc: scr_dets.cons_desc,
										cons_allergy: scr_dets.cons_allergy,
									}}
					>
						{(values, valids, uf_this, fields) => {
							const args = {values, valids, uf_this}

							return <UniFormSUI
										fields={script_model.COMM_DCNS_FIELDS.filter( f => ['cons_phone'].includes(f?.name) )}
										// fields={script_model.COMM_DCNS_FIELDS.filter( f => !['cons_ref_spec', 'cons_ref_name', 'cons_ref_email'].includes(f?.name) )}
										values={values}
										valids={valids}
										uf_this={uf_this}
										// loading={sending}
										show="fields_form"
										//size={'large'}
										btns={ <Button 
													content=" Submit change " 
													color="green" 
													size='large'
													onClick={d => uf_this.actForm(d)} 
													loading={loading}
													// fluid 
												/> }
									/>
						}}

					</UniForm>

			</>
	}

	//	------------------------	------------------------	------------------------

	const render_edit_pht_dets = _ => {

		return <>
					<h4 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
						Photos
					</h4>

					<Photos
							show_disclaim
							size='calc(100% / 3 - 2em)'
							data = {scr_dets.cons_photos || []}
							onChange = { ps => save_phts(ps) }
						/>
			</>
	}

	//	------------------------	------------------------	------------------------

	const render_canc_cons = _ => {

		// return moment().diff(moment(scr_dets.add_tm, "D/M/YY H:m:s"), 'minutes')>110 &&
		return 	<>

					<br/>
					<br/>

						<h4 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
							Cancel Consultation Request
						</h4>
						<Header as='h5'>Cancel your request now</Header>
						<br/>
						<Button
							content='Cancel Consultation'
							color='red'
							size='large'
							loading={loading}
							data-testid='button-cancel'
							onClick={async _=> respondCancelCall() }
						/>
			</>
	}

	//	------------------------	------------------------	------------------------

	const render_call_again = _ => {

		return <>
					<h4 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
						Retry consultation using same contact number
					</h4>

					<p className="text-md font-bold">We tried to call you at <span className="text-lg">{scr_dets.cons_phone}</span> but there was no answer.</p>
					<p>Please be sure to have your phone nearby and enabled to receive private numbers phone calls.</p>
					<p>To let us know you would like us to try call you again using the same mobile number:</p>

					<Button
							content='Request to call again on the same mobile number'
							color='green'
							size='large' 
							loading={loading}
							onClick={async _=> respondCallSameNumber() }
						/>
			</>
	}

	const confirm_callback = _ => {

        const fields = script_model.COMM_DCNS_FIELDS.filter( f =>
            ['call_hour_restriction'].includes(f?.name) &&
            (new Date().getHours() >= 21 || new Date().getHours() < 5)
        );
		return <>
					<p className="text-md font-bold">
						If you'd still like to proceed with the consultation, please confirm below.
					</p>
                    <UniForm
                        onActForm={ (d) => respondCallAgain(d) }
                        render_children={true}
                        debug={true}
                        fields={fields}
                        defaultValues={{
                            cons_phone: mob,
                            call_hour_restriction: scr_dets.call_hour_restriction,
                        }}
                    >
                        {(values, valids, uf_this) => <UniFormSUI
                            fields={fields}
                            values={values}
                            valids={valids}
                            uf_this={uf_this}
                            show='fields_form'
                            btns={ <Button
                                content='Request to call again on the same mobile number'
                                color='green'
                                size='large'
                                onClick={d => uf_this.actForm(d)}
                                loading={loading}
                            /> }
                        />}

                    </UniForm>
					<p className="mt-6">Alternatively, </p>
					<ol>
						<li>You can change your phone number</li>
						<li>You can add additional details for the doctor</li>
						<li>You can cancel the consultation and get a full refund</li>
					</ol>

					
			</>
	}


	const render_issue_paper = ()=>{

		const fields = [
				{
					label: "Select a pharmacy",
					name: "sendto_oid",
					type: "text",
					valid_not_required: false
				}
			]


		return <>
					<h4 className={'text-is-blue pt-4 pb-2 mb-4 border-b-2 border-is-blue '}>
						Choose Paper Script
					</h4>

					<UniForm
						onActForm={ d => respondIssuePaper(d) } 

						render_children={true}
						debug={true}
						fields={fields}

						defaultValues={{}}
					>
						{(values, valids, uf_this) => {
							const args = {values, valids, uf_this}

							return <>
							
							<p>To generate a paper script, please choose the pharmacy you would like us to send the paper script to.</p>
							
							<SearchPharm 
								error={!valids?.sendto_oid}
								placeholder='Select pharmacy'
								onSelect={ (oid, pn, pa, sa, org) => {
										uf_this.handleInputChange(null, {name:'sendto_oid',value:oid}); 
										// uf_this.handleInputChange(null, {name:'send_to_pha',value:{nm:pn, adr:pa, srch_adr:sa}}); 
									} 
								}
								init_addr={app.user.prof?.full_address?.formatted}
							/>

							<br/>
							<Button 
									content="Request paper script" 
									color="green" 
									size='large'
									onClick={d => uf_this.actForm(d)} 
									loading={loading}
									// fluid 
								/>
								</>
				
				}}</UniForm>

			</>
	}


	const render_script_confirm = _ => {
		return <>
					{/* {!embedded && (
						<h2 className='head' >
							<BackButton parent={'/history'} btn_text='Back to History' />
						</h2>
					)} */}

					<h4>{scr_dets.nm&&`Hello ${scr_dets.nm},`||''}</h4>

					<Header as='h4' >You've requested an eScript on {scr_dets.add_tm}</Header>
					<p>{sid}</p>
					<h5>
						Unfortunately we were not able to issue an eScript delivered directly to you.<br/>
						We can create a paper script for you which we will need to send directly to a pharmacy of your choice.<br/>
						Alternatitely you can choose to cancel the request altogether.
					</h5>

					{render_issue_paper()}

					{render_canc_cons()}


				</>
		}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const render_consult = _ => {
		let appointment = null;
		if (scr_dets?.appointment?.active) {
			const bookingTimeZone = getClientBookingTimezone();
			const appointmentStart = moment(scr_dets.appointment.start_time).tz(bookingTimeZone);
			const timeRecent = moment().add(-2, 'hours');
			if (appointmentStart.isSameOrAfter(timeRecent)) {
				appointment = (
					<>
						<p>
							Appointment on <strong>{appointmentStart.format(FRIENDLY_DATETIME_DAY_MONTH)}</strong> <small>({bookingTimeZone} time)</small> <sup>*</sup>
							<br />
							<small>* Appointments times are approximate and may vary. Please allow for additional time if necessary.</small>
						</p>
						<p>
							Should you wish to make any changes to your appointment or have any queries relating to your booking, please contact our Support team via the blue messaging option at the bottom right side of your screen.
						</p>
					</>
				);
			} else {
				appointment = (
					<>
						<p>
							Appointment: <strong>{appointmentStart.format(FRIENDLY_DATETIME_DAY_MONTH)}</strong> <small>({bookingTimeZone} time)</small>
						</p>
						<p>
							Should you have any queries relating to your booking, please contact our Support team via the blue messaging option at the bottom right side of your screen.
						</p>
					</>
				);
			}
		}

		return <>
					{!embedded && (
						<h2 className='head' >
							<BackButton parent={'/history'} btn_text='Back to History' />
						</h2>
					)}

					<h4>{scr_dets.nm&&`Hello ${scr_dets.nm},`||''}</h4>

					<Header as='h4' >You've requested a doctor consultation at {scr_dets.add_tm} ({moment().diff(moment(scr_dets.add_tm, "D/M/YY H:m:s"), 'hours')}h {moment().diff(moment(scr_dets.add_tm, "D/M/YY H:m:s"), 'minutes')%60}m ago)</Header>
					<h5>
						Consultation status: {scripts_list_model.stats_dict(scr_dets?.st)}
					</h5>

					{appointment}

					{status === 'could_not_call' && confirm_callback()}

					{['noans_doccall', 'could_not_call', 'wrong_number', 'await_doccall', 'in_doccall'].includes(status) && <LinkToVideoConsult sid={sid} />}

					{['in_doccall', 'on_hold'].includes(status) && render_view_cons_dets()}

					{['noans_doccall'].includes(status) && render_call_again()}

					{['noans_doccall', 'could_not_call', 'wrong_number', 'await_doccall'].includes(status) && render_edit_cons_dets()}

					{['noans_doccall', 'could_not_call', 'wrong_number', 'on_hold', 'await_doccall', 'in_doccall'].includes(status) && render_edit_pht_dets()}

					{['noans_doccall', 'could_not_call', 'wrong_number', 'await_doccall'].includes(status) && render_canc_cons()}


				</>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	return <Container>
					<Helmet>
						<title>{scripts_list_model.stats_dict(scr_dets?.st)}</title>
					</Helmet>

					{
						status=='loading' && render_loading_page()
						|| status=='done' && <h4>All done. Thank you for providing the required information.</h4>
							|| status=='scr_err' && render_invalid()
							|| script_model.is_epending({status}) && render_script_confirm()
							|| ['noans_doccall', 'wrong_number', 'await_doccall', 'in_doccall', 'cancelled_call', 'user_cancel', 'on_hold', 'could_not_call'].includes(status) && render_consult()
							|| <h1>{status}</h1>
					}

					<br/><br/>
					<p><Link to='/' content='Return to Home' /></p>
				</Container>

};

export default DocCons_req;


